import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Mitra extends BaseModel {
    fields =  {
      'name': {
        type: 'text',
        label: 'nama',
        rules: 'required'
      },
      'address': {
        type: 'text',
        label: 'Alamat',
        rules: 'required'
      },
      'phone_number': {
        type: 'text',
        label: 'Nomor Hp',
        rules: 'required'
      },
      'photo': {
        type: 'attachment',
        label: 'Photo',
        rules: 'required'
      },
      'bio': {
        type: 'textarea',
        label: 'Deskripsi',
        rules: 'required'
      },
      'province_id': {
        type: 'hidden'
      },
      'district_id': {
        type: 'hidden'
      },
      'regency_id': {
        type: 'hidden'
      },
      'village_id': {
        type: 'hidden'
      },
      'is_active': {
        type: 'hidden'
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'partners'
}