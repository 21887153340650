<template>
  <b-row>
    <div class="col-sm-12" id="paginated-table">
      <b-progress
        v-if="isLoading"
        :value="100"
        height="20"
        class="mb-2"
        variant="primary"
        :animated="true"
        :striped="true"
      ></b-progress>
      <div v-show="failedToGetData" class="refresh-modal">
        <p>Failed To Get Data</p>
        <div class="refresh-button text-center">
          <button type="button" @click="getDataTable()" class="btn btn-primary">
            <font-awesome-icon icon="sync-alt" /> Reload
          </button>
        </div>
      </div>
      <p class="mb-1 mt-2">Total {{ tableData.length }} data</p>
      <!-- <el-table ref="paginatedTable" stripe style="width: 100%;" :data="tableData" @sort-change="onSortChange" :default-sort="{prop: pagination.order, order: pagination.ascending ? 'ascending' : 'descending'}"> -->
      <el-table
        ref="paginatedTable"
        v-if="reloadTable"
        border
        stripe
        style="width: 100%;"
        :data="tableData"
        @sort-change="onSortChange"
        @selection-change="handleSelectionChange"
        @row-dblclick="handleCurrentChange"
        empty-text="Data tidak ditemukan"
      >
        <el-table-column v-if="withShowDrop" type="expand" width="55">
          <template #default="props">
            <slot name="expand" :col-data="props.row"></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-if="withSelect"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
          :resizable="column.resizable"
          :align="column.isToggle ? 'center' : column.align"
          :fixed="column.fixed"
        >
          <template slot="header" slot-scope="slot">
            <div
              v-if="column.sortable"
              class="pointer lh-normal p-0"
              @click="doSorting(column.prop)"
            >
              {{ column.label }}
              <font-awesome-icon
                v-if="pagination.order !== column.prop"
                icon="sort"
              />
              <font-awesome-icon
                v-else-if="
                  pagination.order === column.prop && pagination.ascending === 0
                "
                icon="sort-up"
              />
              <font-awesome-icon
                v-else-if="
                  pagination.order === column.prop && pagination.ascending === 1
                "
                icon="sort-down"
              />
            </div>
            <div v-else class="lh-normal p-0">
              {{ column.label }}
            </div>
          </template>
          <template slot-scope="scope">
            <slot
              v-if="column.hasCustomSlot"
              :name="'content_' + column.prop"
              :col-data="scope.row"
            />
            <template v-else-if="column.needCurrency"
              ><div :class="'text-' + column.align">
                {{
                  scope.row[column.prop] !== null
                    ? formatCurrency(scope.row[column.prop])
                    : "-"
                }}
              </div></template
            >
            <template v-else-if="column.isNumeric">{{
              scope.row[column.prop] !== null
                ? parseFloat(scope.row[column.prop]) + 0
                : "-"
            }}</template>
            <template v-else-if="column.formatThisDate">{{
              scope.row[column.prop] !== null
                ? formatDate(scope.row[column.prop])
                : "-"
            }}</template>
            <template v-else-if="column.isToggle"
              ><el-switch
                :width="30"
                class="ml-2"
                v-model="scope.row[column.prop]"
                type="primary"
                :active-value="1"
                :inactive-value="0"
                @change="
                  changeSwitch(
                    column.prop,
                    column.label,
                    scope.row.id,
                    scope.row[column.prop]
                  )
                "
              ></el-switch
            ></template>
            <template v-else
              ><div :class="'text-' + column.align">
                {{ scope.row[column.prop] }}
              </div></template
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="!noActions"
          width="70"
          align="center"
          fixed="right"
          label="Aksi"
        >
          <template slot-scope="scope">
            <template v-if="hasSlot('actionButtons')">
              <slot name="actionButtons" :col-data="scope.row" />
            </template>
            <template v-if="!hasSlot('actionButtons')">
              <template v-if="aksiDot">
                <div class="wrap-show-option">
                  <span
                    :id="'option_' + scope.$index"
                    @click="showOption(scope.$index)"
                    class="h4"
                    ><b-icon
                      v-if="showOptionIndex == scope.$index"
                      class="pointer"
                      icon="x-circle-fill"
                      style="position: relative; z-index: 999;"
                    ></b-icon>
                    <b-icon v-else class="pointer" icon="three-dots-vertical">
                    </b-icon>
                  </span>
                  <div
                    :id="'option_btn_' + scope.$index"
                    class="show-option  d-flex shadow justify-content-start align-items-start display-none flex-column rounded border"
                    style="display: none !important;"
                  >
                    <a :href="getLastPathUrl() + scope.row.id">Lihat Detail</a>
                    <a
                      v-if="canEdit"
                      :href="getLastPathUrl() + scope.row.id + '/edit'"
                      >Edit</a
                    >
                    <slot name="addActionOption" :col-data="scope.row" />
                    <span
                      v-if="(deleteCriteria === null) & canDelete"
                      @click="doDeleteData(scope.row.id)"
                      class="text-danger"
                      >Hapus</span
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-center table-actions ">
                  <template>
                    <b-button
                      style="width: 50px; height:35px"
                      variant="primary"
                      size="sm"
                      :href="getLastPathUrl() + scope.row.id"
                      class="mb-0 text-center detail mr-1"
                    >
                      <font-awesome-icon icon="info" />
                    </b-button>
                  </template>
                  <template v-if="canEdit">
                    <b-button
                      style="width: 50px; height:35px"
                      variant="warning"
                      size="sm"
                      :href="getLastPathUrl() + scope.row.id + '/edit'"
                      class="mb-0 text-center text-white edit"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </b-button>
                  </template>
                  <template v-if="hasSlot('addActionButtons')">
                    <slot name="addActionButtons" :col-data="scope.row" />
                  </template>
                  <template
                    v-if="
                      (deleteCriteria !== null) &
                        applyDeleteCriteria(scope.row) && canDelete
                    "
                  >
                    <b-button
                      style="width: 50px; height:35px"
                      variant="danger"
                      size="sm"
                      type="button"
                      v-on:click.stop.prevent="doDeleteData(scope.row.id)"
                      class="mb-0 text-center delete ml-1"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                  </template>
                  <template v-if="(deleteCriteria === null) & canDelete">
                    <b-button
                      style="width: 50px; height:35px"
                      variant="danger"
                      size="sm"
                      type="button"
                      v-on:click.stop.prevent="doDeleteData(scope.row.id)"
                      class="mb-0 text-center delete ml-1"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                  </template>
                </div>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="col-12 mt-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="w-100">
        <!-- <p class="card-category">Showing {{pagination.from + 1}} to {{pagination.to}} of {{pagination.total}} entries</p> -->
        <b-row>
          <b-col md="10">
            <b-pagination
              v-if="pagination.total > dataLimit"
              size="sm"
              class="pagination-no-border mt-1"
              @change="onChangePage"
              v-model="pagination.currentPage"
              :per-page="dataLimit"
              :total-rows="pagination.total"
            >
            </b-pagination>
          </b-col>
          <b-col md="2">
            <slot name="footer"></slot>
          </b-col>
        </b-row>
        <p :class="{ 'mb-2': pagination.total > 1 }">
          Total {{ tableData.length }} data
        </p>
      </div>
    </div>
  </b-row>
</template>

<script>
import { formatModelValue } from "@/_helpers";
export default {
  props: {
    dataUrl: {
      type: String,
      required: true,
    },
    deleteUrl: {
      type: String,
      required: false,
      default: null,
    },
    // dataKey: {
    //   type: String,
    //   required: true
    // },

    dataColumns: {
      type: Array,
      required: true,
    },
    dataOrderField: {
      type: String,
      default: "created_at",
    },
    dataOrderAsc: {
      type: Number,
      default: 0,
    },
    dataLimit: {
      type: Number,
      default: 30,
    },
    dataParams: {
      type: Array,
      required: false,
      default: function() {
        return [];
      },
    },
    defaultDataParams: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    deleteCriteria: {
      type: Object,
      required: false,
      default: null,
    },
    noActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    withShowDrop: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPushParam: {
      type: Boolean,
      required: false,
      default: false,
    },
    withSelect: {
      type: Boolean,
      default: false,
    },
    deleteReason: {
      type: Boolean,
      required: false,
      default: false,
    },
    aksiDot: {
      type: Boolean,
      require: false,
      default: false,
    },
    withEmptyFirst: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      showOptionIndex: null,
      showOptionAction: false,
      reloadTable: true,
      isLoading: false,
      failedToGetData: false,
      pagination: {
        // dataLimit: 20,
        currentPage:
          this.$route.query.page !== undefined
            ? parseInt(this.$route.query.page)
            : 1,
        total: 0,
        order: null,
        ascending: 0,
      },
      propsToSearch: ["name", "email", "age"],
      tableColumns: [],
      tableData: [],
    };
  },
  watch: {
    "pagination.currentPage": function(newVal) {
      this.$emit("changePage", newVal);
    },
  },
  // computed: {
  //   from () {
  //     return this.dataLimit * (this.pagination.currentPage - 1)
  //   },
  //   to () {
  //     let highBound = this.from + this.dataLimit
  //     if (this.total < highBound) {
  //       highBound = this.total
  //     }
  //     return highBound
  //   }
  // },
  computed: {
    getTableData() {
      return this.tableData;
    },
    from() {
      return this.dataLimit * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.dataLimit;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  methods: {
    handleSelectionChange: function(val) {
      this.$emit("selection-change", val);
    },
    formatCurrency(value) {
      return formatModelValue("price", value);
    },

    initData() {
      const t = this;
      return {
        params() {
          return new Promise((resolve, reject) => {
            t.pagination.order =
              t.$route.query.order !== undefined
                ? t.$route.query.order
                : t.dataOrderField;
            t.pagination.ascending = parseInt(
              t.$route.query.ascending !== undefined
                ? t.$route.query.ascending
                : t.dataOrderAsc
            );
            resolve("done");
          });
        },
      };
    },
    setData() {
      const t = this;
      console.log(t.pagination.currentPage, 'pagination')
      return {
        params(replaceOnly = false, addititonalData = []) {
          let arrayParams = [];
          arrayParams["limit"] = t.dataLimit;
          arrayParams["page"] = t.pagination.currentPage;
          arrayParams["order"] = t.pagination.order;
          arrayParams["ascending"] = t.pagination.ascending;
          t.dataParams.forEach((key) => {
            if (addititonalData[key] !== undefined) {
              if (
                addititonalData[key] !== "" &&
                addititonalData[key] !== null &&
                addititonalData[key] !== "null" &&
                addititonalData[key] !== ""
              ) {
                arrayParams[key] =
                  typeof addititonalData[key] === "object"
                    ? addititonalData[key].join()
                    : addititonalData[key];
              }
            } else if (
              t.$route.query[key] !== undefined &&
              t.$route.query[key] !== null &&
              t.$route.query[key] !== "" &&
              t.$route.query[key] !== "null"
            ) {
              arrayParams[key] =
                typeof t.$route.query[key] === "object"
                  ? t.$route.query[key].join()
                  : t.$route.query[key];
            } else if (
              t.defaultDataParams[key] !== undefined &&
              t.defaultDataParams[key] !== null &&
              t.defaultDataParams[key] !== "" &&
              t.defaultDataParams[key] !== "null"
            ) {
              arrayParams[key] =
                typeof t.defaultDataParams[key] === "object"
                  ? t.defaultDataParams[key].join()
                  : t.defaultDataParams[key];
            }
          });
          if (!t.noPushParam) {
            t.$router.replace({ query: arrayParams }).catch((err) => {});
          }
          if (!replaceOnly) {
            let stringParams = "";
            let urlParam = "";
            Object.keys(arrayParams).forEach((key) => {
              if (key !== "limit" && key !== "page") {
                urlParam +=
                  (urlParam === "" ? "?" : "&") + key + "=" + arrayParams[key];
              }
              stringParams +=
                (stringParams === "" ? "?" : "&") +
                key +
                "=" +
                arrayParams[key];
            });
            /* t.dataParams.forEach((key) => {
                if (addititonalData[key] !== undefined) {
                  if (addititonalData[key] !== '' && addititonalData[key] !== null) {
                    stringParams += '&' + key + '=' + addititonalData[key]
                  }
                } else if (t.$route.query[key] !== undefined) {
                  stringParams += '&' + key + '=' + t.$route.query[key]
                } else if (t.defaultDataParams[key] !== undefined) {
                  stringParams += '&' + key + '=' + t.defaultDataParams[key]
                }
              }) */
            t.$emit("getBackUrlParams", urlParam);
            return stringParams;
          }
        },
      };
    },
    initTableColumns() {
      this.tableColumns = [];
      for (let i = 0; i < this.dataColumns.length; i++) {
        this.tableColumns.push({
          prop: this.dataColumns[i].prop,
          label:
            this.dataColumns[i].label === undefined ||
            this.dataColumns[i].label === null
              ? this.dataColumns[i].prop.charAt(0).toUpperCase() +
                this.dataColumns[i].prop.slice(1)
              : this.dataColumns[i].label,
          minWidth:
            this.dataColumns[i].minWidth === undefined
              ? null
              : this.dataColumns[i].minWidth,
          sortable:
            this.dataColumns[i].sortable === undefined
              ? null
              : this.dataColumns[i].sortable,
          resizable:
            this.dataColumns[i].resizable === undefined
              ? null
              : this.dataColumns[i].resizable,
          isToggle:
            this.dataColumns[i].isToggle === undefined
              ? null
              : this.dataColumns[i].isToggle,
          formatThisDate:
            this.dataColumns[i].formatThisDate === undefined
              ? false
              : this.dataColumns[i].formatThisDate,
          isNumeric:
            this.dataColumns[i].isNumeric === undefined
              ? false
              : this.dataColumns[i].isNumeric,
          hasCustomSlot: this.hasSlot("content_" + this.dataColumns[i].prop),
          align:
            this.dataColumns[i].align === undefined
              ? "left"
              : this.dataColumns[i].align,
          needCurrency:
            this.dataColumns[i].needCurrency === undefined
              ? false
              : this.dataColumns[i].needCurrency,
          fixed:
            this.dataColumns[i].fixed === undefined
              ? null
              : this.dataColumns[i].fixed,
        });
      }
    },
    getDataTable(params = []) {
      this.isLoading = true;
      this.failedToGetData = false;
      console.log(this.setData().params(false, params))
      this.$http
        .get(
          this.dataUrl +
            (this.dataUrl.indexOf("?") !== -1
              ? "&" +
                this.setData()
                  .params(false, params)
                  .substr(1)
              : this.setData().params(false, params))
        )
        .then((response) => {
          this.tableData = response.data.data;
          this.pagination.total = response.data.meta?.total;
          this.pagination.from = response.data.meta?.from;
          this.pagination.to = response.data.meta?.to;
          this.isLoading = false;
          let toggleDatas = [];
          this.tableColumns.forEach((tableColumn) => {
            if (tableColumn.isToggle) {
              toggleDatas.push(tableColumn.prop);
            }
          });
          // this.pagination.currentPage = response.data.meta.current_page
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.failedToGetData = true;
          // this.$SmoothScroll(this.$el.querySelector('#dataTable'))
        });
    },
    setPage(page) {
      this.pagination.currentPage = page;
    },
    onChangePage(e) {
      console.log(e, 'onChangePage');
      this.pagination.currentPage = e
      this.getDataTable(e);
    },
    doSorting(column) {
      this.onSortChange({
        prop: column,
        order:
          this.pagination.order !== column
            ? "ascending"
            : !this.pagination.ascending
            ? "ascending"
            : "descending",
      });
      // this.$refs.paginatedTable.sort(column, this.pagination.order !== column ? 'ascending' : (!this.pagination.ascending ? 'ascending' : 'descending'))
      this.getDataTable();
    },
    doFilter(params) {
      this.setPage(1);
      this.setData().params(true, params);
      this.getDataTable();
    },
    onSortChange(column) {
      if (column.prop !== null && column.order) {
        this.pagination.order = column.prop;
        this.pagination.ascending = column.order === "ascending" ? 1 : 0;
        this.setData().params(true);
        this.getDataTable();
      }
    },
    doDeleteData(id) {
      if (this.deleteReason) {
        this.$swal
          .fire({
            input: "textarea",
            inputPlaceholder: "Alasan Dihapus",
            title: "Anda Yakin?",
            text: "Data tidak akan dapat dikembalikan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
            // confirmButtonClass: 'btn btn-danger mr-3',
            // cancelButtonClass: 'btn btn-default',
            // buttonsStyling: false
          })
          .then((result) => {
            if (typeof result.value !== "undefined") {
              if (result.value !== "") {
                this.$http
                  .post(
                    (this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) +
                      "/" +
                      id,
                    {
                      reason: result.value,
                      _method: "DELETE",
                    }
                  )
                  .then((response) => {
                    this.$store.dispatch(
                      "notification/success",
                      "Berhasil menghapus."
                    );
                    this.getDataTable();
                    this.$emit("afterDeleteData", id);
                  })
                  .catch((error) => {
                    this.$store.dispatch(
                      "notification/error",
                      "Gagal menghapus data."
                    );
                    console.log(error);
                  });
              } else {
                this.$store.dispatch(
                  "notification/error",
                  "Alasan wajib diisi."
                );
              }
            }
          });
      } else {
        this.$swal
          .fire({
            title: "Anda Yakin?",
            text: "Data tidak akan dapat dikembalikan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
            // confirmButtonClass: 'btn btn-danger mr-3',
            // cancelButtonClass: 'btn btn-default',
            // buttonsStyling: false
          })
          .then((result) => {
            if (result.value) {
              this.$http
                .post(
                  (this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) +
                    "/" +
                    id,
                  {
                    _method: "DELETE",
                  }
                )
                .then((response) => {
                  this.$store.dispatch(
                    "notification/success",
                    "Berhasil menghapus."
                  );
                  this.getDataTable();
                  this.$emit("afterDeleteData", id);
                })
                .catch((error) => {
                  this.$store.dispatch(
                    "notification/error",
                    "Gagal menghapus data."
                  );
                  console.log(error);
                });
            }
          });
      }
    },
    showOption: function(index) {
      const e = document.querySelectorAll("#option_btn_" + index)[1];
      const eBtn = document.querySelectorAll("#option_" + index)[1];
      const top = eBtn.getBoundingClientRect().top + "px";

      if (this.showOptionIndex == index) {
        e.style = "display: none !important";
        this.showOptionIndex = null;
        return;
      }

      if (this.showOptionIndex != null) {
        const eBefore = document.querySelectorAll(
          "#option_btn_" + this.showOptionIndex
        )[1];
        eBefore.style = "display: none !important";
        console.log(eBefore);
      }

      e.classList.remove("display-none");
      console.log(top);
      e.style = `top: calc(${top} + 62px)`;
      this.showOptionIndex = index;
      window.document.body.onscroll = () => {
        if (!e.classList.contains("display-none")) {
          e.classList.add("display-none");
          e.style = "display: none !important";
          console.log(this.showOptionIndex);
          this.showOptionIndex = null;
        }
        window.document.body.onscroll = null;
      };
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    formatDate(date) {
      return this.$moment(date).format(process.env.VUE_APP_DATE_FORMAT_SHORT);
    },
    getLastPathUrl() {
      let tmp = this.$route.matched[this.$route.matched.length - 2].path;
      if (tmp === "" || tmp.slice(-1) !== "/") tmp += "/";
      return "#" + tmp;
    },
    changeSwitch(prop, label, id, value) {
      this.$swal
        .fire({
          title: "Anda yakin?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          // confirmButtonClass: 'btn btn-warning mr-3',
          // cancelButtonClass: 'btn btn-default',
          // buttonsStyling: false
        })
        .then((result) => {
          if (result.value) {
            let data = {};
            data[prop] = value;
            this.$http
              .patch(this.dataUrl + "/" + id, data)
              .then((response) => {
                this.$store.dispatch(
                  "notification/success",
                  label + " Berhasil diperbaharui."
                );
              })
              .catch((error) => {
                const filteredTableData = this.tableData.filter((data) => {
                  return data.id === id;
                });
                this.$store.dispatch("notification/error", error);
                filteredTableData[0][prop] = value ? 0 : 1;
              });
          } else {
            const filteredTableData = this.tableData.filter((data) => {
              return data.id === id;
            });
            filteredTableData[0][prop] = value ? 0 : 1;
          }
        });
    },
    applyDeleteCriteria(row) {
      if (this.deleteCriteria !== null) {
        if (this.deleteCriteria.criteria === "===") {
          return row[this.deleteCriteria.column] === this.deleteCriteria.value;
        } else if (this.deleteCriteria.criteria === "<") {
          return row[this.deleteCriteria.column] < this.deleteCriteria.value;
        } else if (this.deleteCriteria.criteria === ">") {
          return row[this.deleteCriteria.column] > this.deleteCriteria.value;
        } else if (this.deleteCriteria.criteria === ">=") {
          return row[this.deleteCriteria.column] >= this.deleteCriteria.value;
        } else if (this.deleteCriteria.criteria === "<=") {
          return row[this.deleteCriteria.column] <= this.deleteCriteria.value;
        }
      }
    },
    handleCurrentChange(val) {
      this.$router.push(`/order/${val.id}`);
    },
    pointerCursorRow() {
      setTimeout(() => {
        document.querySelectorAll(".el-table__row").forEach(e => {
          e.classList.add("pointer");
        })
      }, 500);
    },
  },
  mounted() {
    this.initData()
      .params()
      .then(() => {
        this.initTableColumns();
        if (!this.withEmptyFirst) this.getDataTable();
        this.pointerCursorRow()
      });
  },
};
</script>
